import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store/';
import { Alert, AlertType } from './types/';

/**
 * VuexModule for alert management
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Module({ store: store, namespaced: true, name: 'alert', dynamic: true })
class AlertModule extends VuexModule {
    // Alert Array
    private _alerts: Array<Alert> = [];

    /**
     * Adds the given alert to the _alerts array
     * 
     * @param alert alert object
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private ADD_ALERT(alert: Alert) {
        this._alerts.push(alert);
    }

    /**
     * Creates a new alert object with the given values and calls SET_ALERT
     * 
     * @param payload object with message (string) and type (AlertType) properties
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public showAlert(payload: { message: string; type: AlertType }) {
        const alert: Alert = {
            message: payload.message,
            type: payload.type,
            show: true
        }

        this.ADD_ALERT(alert);
    }

    /** 
     * @returns Alert array
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    public get alerts(): Array<Alert> {
        return this._alerts;
    }
}

// Exports AlertModule
export default getModule(AlertModule);

// Export all types
export * from './types';