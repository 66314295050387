export interface Alert {
    show: boolean;
    type: AlertType;
    message: string;
}

export enum AlertType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error'
}
